// Externals
import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';

// Reducers
import reducer from './reducer';

// API
import { accountApi } from '../services/accounts';
import { adminApi } from '../services/admins';
import { answersApi } from '../services/answers';
import { auditLevelGridsApi } from '../services/auditLevelGrids';
import { auditsApi } from '../services/audits';
import { audit360Api } from '../services/Audit360';
import { billingApi } from '../services/billing';
import { blogPrivateApi, blogPublicApi } from '../services/blog';
import { choicesApi } from '../services/choices';
import { codesApi } from '../services/codes';
import { connectorsApi } from '../services/connectors';
import { contactApi, contactPrivateApi } from '../services/contact';
import { districtLevelGridsApi } from '../services/districtLevelGrids';
import { districtsApi } from '../services/districts';
import { emailLeakDetectorApi } from '../services/EmailLeakDetector';
import { reportsApi } from '../services/emailReport';
import { languageApi } from '../services/languages';
import { logsApi } from '../services/Logs';
import { organizationPermissionsApi } from '../services/organization-permissions';
import { organizationRoleApi } from '../services/organization-roles';
import { organizationMemberApi } from '../services/organizationMember';
import { organizationsApi } from '../services/organizations';
import { questionsApi } from '../services/questions';
import { questsApi } from '../services/quests';
import { socialDataApi } from '../services/socialData';
import { storageUserApi } from '../services/storage-user';
import { taskItemApi } from '../services/taskitems';
import { tipsApi } from '../services/tips';
import { translationApi } from '../services/traductions';
import { storageApi } from '../services/storages';
import { userPrivateApi, userPublicApi } from '../services/userProfiles';
import { historicalDataApi } from '../services/historical-data';
import { cyberPlanApi } from '../services/cyberplan';

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat([
    accountApi.middleware,
    adminApi.middleware,
    answersApi.middleware,
    auditLevelGridsApi.middleware,
    auditsApi.middleware,
    audit360Api.middleware,
    billingApi.middleware,
    blogPrivateApi.middleware,
    blogPublicApi.middleware,
    choicesApi.middleware,
    codesApi.middleware,
    connectorsApi.middleware,
    contactApi.middleware,
    cyberPlanApi.middleware,
    districtLevelGridsApi.middleware,
    districtsApi.middleware,
    emailLeakDetectorApi.middleware,
    reportsApi.middleware,
    languageApi.middleware,
    logsApi.middleware,
    organizationPermissionsApi.middleware,
    organizationRoleApi.middleware,
    organizationMemberApi.middleware,
    organizationsApi.middleware,
    questionsApi.middleware,
    questsApi.middleware,
    socialDataApi.middleware,
    storageUserApi.middleware,
    taskItemApi.middleware,
    tipsApi.middleware,
    translationApi.middleware,
    storageApi.middleware,
    userPrivateApi.middleware,
    userPublicApi.middleware,
    historicalDataApi.middleware,
    contactPrivateApi.middleware
  ]);

const store = configureStore({
  devTools: process.env.NEXT_PUBLIC_ENABLE_REDUX_DEV_TOOLS === 'true',
  reducer,
  middleware
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
