// Constants
import * as constants from '../../constants';

// Externals
import { createTheme, responsiveFontSizes } from '@mui/material';
import type { Theme, ThemeOptions } from '@mui/material';
import type { PaletteColor, PaletteColorOptions } from '@mui/material/styles';
import merge from 'lodash/merge';

// Shadows
import { darkShadows, lightShadows } from '../shadows';

interface ThemeProps {
  theme: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    complementary: PaletteColor;
    argent: PaletteColor;
    bronze: PaletteColor;
    gold: PaletteColor;
    yellow: PaletteColor;
    orange: PaletteColor;
    red: PaletteColor;
    pink: PaletteColor;
    purple: PaletteColor;
    green: PaletteColor;
    gray: PaletteColor;
  }
  interface PaletteOptions {
    complementary: PaletteColorOptions;
    argent: PaletteColorOptions;
    bronze: PaletteColorOptions;
    gold: PaletteColorOptions;
    yellow: PaletteColorOptions;
    orange: PaletteColorOptions;
    red: PaletteColorOptions;
    pink: PaletteColorOptions;
    purple: PaletteColorOptions;
    green: PaletteColorOptions;
    gray: PaletteColorOptions;
  }
  interface Theme {
    kpi: {
      marketing: { theme: string; highlight: string };
      salesfreet: { theme: string; highlight: string };
      ai: { theme: string; highlight: string };
      cybersecurity: { theme: string; highlight: string };
      regulatory: { theme: string; highlight: string };
      benchmark: { theme: string; highlight: string };
    };
    graph: {
      up: string;
      down: string;
    };
  }
  interface ThemeOptions {
    kpi?: {
      marketing?: { theme?: string; highlight?: string };
      salesfreet?: { theme?: string; highlight?: string };
      ai?: { theme?: string; highlight?: string };
      cybersecurity?: { theme?: string; highlight?: string };
      regulatory?: { theme?: string; highlight?: string };
      benchmark?: { theme?: string; highlight?: string };
    };
    graph?: {
      up?: string;
      down?: string;
    };
  }
}

const baseOptions: ThemeOptions = {
  kpi: {
    marketing: { theme: '#E3ED96', highlight: '#E2FF07' },
    salesfreet: { theme: '#9FF4DD', highlight: '#3DFFCB' },
    ai: { theme: '#F4948F', highlight: '#FF645C' },
    cybersecurity: { theme: '#F8BC85', highlight: '#FF9431' },
    regulatory: { theme: '#D7BAFB', highlight: '#B377FE' },
    benchmark: { theme: '#FD6C9E', highlight: '#F80396' }
  },
  graph: {
    up: '#21D33D',
    down: '#FF3131'
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paperWidthXs: {
          margin: 6
        },
        paperWidthSm: {
          margin: 6
        },
        paper: {
          overflowY: 'visible'
        },
        paperScrollBody: {
          width: 'calc(100% - 12px)'
        },
        root: {}
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            padding: 16
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          background: '#727BFE !important',
          zIndex: '2000 !important'
        },
        '#nprogress .peg': {
          boxShadow: '0 0 10px #727BFE, 0 0 5px #727BFE !important'
        },
        '#nprogress .spinner-icon': {
          borderTopColor: '#727BFE !important',
          borderLeftColor: '#727BFE !important'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 16,
          whiteSpace: 'pre-line'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          paddingBottom: 6,
          paddingTop: 6,
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      defaultProps: {}
    },
    MuiInputBase: {
      styleOverrides: {
        inputAdornedStart: {
          paddingLeft: '8px !important'
        },
        inputAdornedEnd: {
          paddingRight: '8px !important'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            backgroundColor: '#3D4044',
            borderRadius: '10px',
            border: 'none'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px'
          }
        }
      },
      defaultProps: {
        InputProps: {},
        InputLabelProps: {
          style: {
            fontFamily: 'Inter'
          }
        },
        variant: 'outlined'
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          body1: 'p',
          body2: 'p',
          subtitle1: 'p',
          subtitle2: 'p'
        }
      }
    }
  },
  shape: {
    borderRadius: 20
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    h1: {
      fontFamily: 'Exo, sans-serif',
      fontSize: 42,
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: 50 / 42
    },
    h2: {
      fontFamily: 'Exo, sans-serif',
      fontSize: 36,
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: 42 / 36
    },
    h3: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 30,
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: 'normal',
      lineHeight: 34 / 30
    },
    h4: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 26,
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: 30 / 26
    },
    h5: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 22,
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: 26 / 22
    },
    h6: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: 18 / 16
    },
    body1: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 24 / 16
    },
    body2: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 26 / 18
    },
    subtitle1: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 18 / 12
    },
    subtitle2: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 22 / 14
    },
    button: {
      fontFamily: 'Exo, sans-serif',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0.02em',
      lineHeight: 16 / 16,
      textTransform: 'none'
    },
    caption: {
      fontFamily: 'Exo, sans-serif',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 12 / 12
    },
    overline: {
      fontFamily: 'Exo, sans-serif',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 800,
      letterSpacing: '0.2em',
      lineHeight: 12 / 12,
      textTransform: 'uppercase'
    }
  }
};

const themesOptions: Record<string, ThemeOptions> = {
  [constants.THEMES.LIGHT]: {
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          sizeSmall: {
            fontSize: 14,
            '&.MuiButton-contained': {
              paddingBottom: 6,
              paddingTop: 6,
              paddingLeft: 16,
              paddingRight: 16
            },
            '&.MuiButton-outlined': {
              paddingBottom: 4,
              paddingTop: 4,
              paddingLeft: 16,
              paddingRight: 16
            },
            '&.MuiButton-text': {
              paddingBottom: 6,
              paddingTop: 6,
              paddingLeft: 16,
              paddingRight: 16
            }
          },
          sizeMedium: {
            fontSize: 16,
            '&.MuiButton-contained': {
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 32,
              paddingRight: 32
            },
            '&.MuiButton-outlined': {
              paddingBottom: 8,
              paddingTop: 8,
              paddingLeft: 32,
              paddingRight: 32
            },
            '&.MuiButton-text': {
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 32,
              paddingRight: 32
            }
          },
          sizeLarge: {
            fontSize: 18,
            '&.MuiButton-contained': {
              paddingBottom: 12,
              paddingTop: 12,
              paddingLeft: 48,
              paddingRight: 48
            },
            '&.MuiButton-outlined': {
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 48,
              paddingRight: 48
            },
            '&.MuiButton-text': {
              paddingBottom: 12,
              paddingTop: 12,
              paddingLeft: 48,
              paddingRight: 48
            }
          },
          containedInherit: {
            transitionProperty: 'opacity',
            '&': {
              background: '#FFFFFF',
              color: '#000000DE',
              borderRadius: '8px'
            },
            '&:disabled': {
              color: '#000000DE',
              borderRadius: '8px'
            },
            '&:hover': {
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          containedPrimary: {
            transitionProperty: 'opacity',
            '&': {
              background: '#000',
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: '#828282',
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:hover': {
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          containedSecondary: {
            transitionProperty: 'opacity',
            '&': {
              background: 'linear-gradient(90deg, #FFF 0%, #FFFFFF 100%)',
              color: '#212121',
              border: '1px solid #212121',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: '#21212159',
              color: '#212121',
              borderRadius: '8px'
            },
            '&:hover': {
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          outlinedPrimary: {
            transitionProperty: 'opacity',
            '&': {
              background:
                'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, #727BFE 0%, #727BFE 100%) border-box',
              borderColor: 'transparent',
              borderWidth: 2,
              color: '#727BFE',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: 'none',
              borderColor: '#727BFE59',
              borderWidth: 2,
              color: '#00000059',
              borderRadius: '8px'
            },
            '&:hover': {
              background:
                'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, #727BFE 0%, #727BFE 100%) border-box',
              borderColor: 'transparent',
              borderWidth: 2,
              color: '#828282',
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          outlinedSecondary: {
            transitionProperty: 'opacity',
            '&': {
              background:
                'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, #212121 0%, #5D5D5D 100%) border-box',
              borderColor: 'transparent',
              borderWidth: 2,
              color: '#000000DE',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: 'none',
              borderColor: '#21212159',
              borderWidth: 2,
              color: '#00000059',
              borderRadius: '8px'
            },
            '&:hover': {
              background:
                'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, #212121 0%, #5D5D5D 100%) border-box',
              borderColor: 'transparent',
              borderWidth: 2,
              color: '#000000DE',
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          textPrimary: {
            '&:disabled': {
              color: '#21212159'
            }
          },
          textSecondary: {
            '&:disabled': {
              color: '#21212159'
            }
          }
        }
      },
      MuiFab: {
        styleOverrides: {
          colorInherit: {
            transitionProperty: 'opacity',
            '&': {
              background: '#FFFFFF',
              color: '#000000DE'
            },
            '&:disabled': {
              color: '#000000DE'
            },
            '&:hover': {
              opacity: 0.8
            }
          },
          primary: {
            transitionProperty: 'opacity',
            '&': {
              background: 'linear-gradient(90deg, #727BFE 0%, #727BFE 100%)',
              color: '#FFFFFF'
            },
            '&:disabled': {
              background: '#727BFE59',
              color: '#FFFFFF'
            },
            '&:hover': {
              opacity: 0.8
            }
          },
          secondary: {
            transitionProperty: 'opacity',
            '&': {
              background: 'linear-gradient(90deg, #212121 0%, #5D5D5D 100%)',
              color: '#FFFFFF'
            },
            '&:disabled': {
              background: '#21212159',
              color: '#FFFFFF'
            },
            '&:hover': {
              opacity: 0.8
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            background: '#0000000D',
            borderRadius: 20,
            overflow: 'hidden'
          },
          input: {
            color: '#000000DE',
            fontFamily: 'Inter, sans-serif',
            fontSize: '1rem !important',
            fontStyle: 'normal',
            fontWeight: 400,
            letterSpacing: 'normal',
            lineHeight: '1.5rem !important',
            paddingBottom: 6,
            paddingTop: 6,
            paddingLeft: 16,
            paddingRight: 16
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#828282',
            fontFamily: 'Inter, sans-serif',
            fontSize: '0.825rem !important',
            fontStyle: 'normal',
            fontWeight: 800,
            letterSpacing: '0.2em',
            lineHeight: '1rem !important',
            textTransform: 'capitalize'
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          colorPrimary: {
            color: '#727BFE',
            padding: 4,
            '&.Mui-checked': {
              color: '#727BFE'
            },
            '& .MuiSvgIcon-root': {
              fontSize: 14
            }
          },
          colorSecondary: {
            color: '#212121',
            padding: 4,
            '&.Mui-checked': {
              color: '#212121'
            },
            '& .MuiSvgIcon-root': {
              fontSize: 14
            }
          },
          root: {
            padding: 4,
            '& .MuiSvgIcon-root': {
              fontSize: 14
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: '#727BFE',
            marginRight: 8
          }
        }
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            height: 28,
            width: 28,
            backgroundColor: '#FFFFFF'
          },
          rail: {
            height: 12,
            color: '#0000000D',
            opacity: 1
          },
          track: {
            height: 12,
            color: 'transparent'
          },
          mark: {
            color: 'transparent'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
              border: 'none'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '10px'
            }
          }
        },
        defaultProps: {
          InputProps: {},
          InputLabelProps: {
            style: {
              fontFamily: 'Inter'
            }
          },
          variant: 'outlined'
        }
      }
    },
    palette: {
      mode: 'light',

      background: {
        default: '#FFFFFF',
        paper: '#F8F9FA'
      },
      text: {
        primary: '#212529',
        secondary: '#767C83'
      },
      primary: {
        main: '#727BFE',
        light: '#b0eff4'
      },
      secondary: {
        main: '#212121',
        light: '#F2F2F2'
      },
      complementary: {
        main: '#9FFF1F',
        light: '#F3FFE3'
      },
      argent: {
        main: '#c0c0c0'
      },
      bronze: {
        main: '#b08d57'
      },
      gold: {
        main: '#ffd700'
      },
      info: {
        main: '#98BAEC'
      },
      success: {
        main: '#89D365',
        light: '#E7F6E0'
      },
      warning: {
        main: '#F5C42D',
        light: '#FEEBC8'
      },
      error: {
        main: '#E44E2D',
        light: '#ECC5BD'
      },
      yellow: {
        main: '#F8E003'
      },
      orange: {
        main: '#E49F2D'
      },
      red: {
        main: '#FF4545'
      },
      pink: {
        main: '#F80396'
      },
      purple: {
        main: '#8154FF'
      },
      green: {
        main: '#9FD408',
        light: '#CDFE8C'
      },
      gray: {
        main: '#6F6F6F'
      }
    },
    shadows: lightShadows
  },
  [constants.THEMES.DARK]: {
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          sizeSmall: {
            fontSize: 14,
            '&.MuiButton-contained': {
              paddingBottom: 6,
              paddingTop: 6,
              paddingLeft: 16,
              paddingRight: 16
            },
            '&.MuiButton-outlined': {
              paddingBottom: 4,
              paddingTop: 4,
              paddingLeft: 16,
              paddingRight: 16
            },
            '&.MuiButton-text': {
              paddingBottom: 6,
              paddingTop: 6,
              paddingLeft: 16,
              paddingRight: 16
            }
          },
          sizeMedium: {
            fontSize: 16,
            '&.MuiButton-contained': {
              paddingBottom: 8,
              paddingTop: 8,
              paddingLeft: 16,
              paddingRight: 16
            },
            '&.MuiButton-inherit': {
              paddingBottom: 8,
              paddingTop: 8,
              paddingLeft: 8,
              paddingRight: 8
            },
            '&.MuiButton-outlined': {
              paddingBottom: 8,
              paddingTop: 8,
              paddingLeft: 16,
              paddingRight: 16
            },
            '&.MuiButton-text': {
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 32,
              paddingRight: 32
            }
          },
          sizeLarge: {
            fontSize: 18,
            '&.MuiButton-contained': {
              paddingBottom: 16,
              paddingTop: 16,
              paddingLeft: 20,
              paddingRight: 20
            },
            '&.MuiButton-inherit': {
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 10,
              paddingRight: 10
            },
            '&.MuiButton-outlined': {
              paddingBottom: 10,
              paddingTop: 10,
              paddingLeft: 48,
              paddingRight: 48
            },
            '&.MuiButton-text': {
              paddingBottom: 12,
              paddingTop: 12,
              paddingLeft: 48,
              paddingRight: 48
            }
          },
          containedInherit: {
            transitionProperty: 'opacity',
            '&': {
              background: '#212529',
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:disabled': {
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:hover': {
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          containedPrimary: {
            transitionProperty: 'opacity',
            '&': {
              background: 'linear-gradient(90deg, #727BFE 0%, #727BFE 100%)',
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: '#5D5D5D',
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:hover': {
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          containedSecondary: {
            transitionProperty: 'opacity',
            '&': {
              background: 'linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%)',
              color: '#212529',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: '#21212159',
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:hover': {
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          outlinedPrimary: {
            transitionProperty: 'opacity',
            '&': {
              background:
                'linear-gradient(#212529, #212529) padding-box, linear-gradient(90deg, #212529 0%, #212529 100%) border-box',
              borderColor: '#FFFFFF',
              borderWidth: 1,
              color: '#FFFFFF',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: 'none',
              borderColor: '#FFFFFF59',
              borderWidth: 2,
              color: '#FFFFFF59',
              borderRadius: '8px'
            },
            '&:hover': {
              background:
                'linear-gradient(#212529, #212529) padding-box, linear-gradient(90deg, #212529 0%, #212529 100%) border-box',
              borderColor: '#727BFE',
              borderWidth: 1,
              color: '#FFFFFF',
              borderRadius: '8px'
            }
          },
          outlinedSecondary: {
            transitionProperty: 'opacity',
            '&': {
              background:
                'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, #212121 0%, #5D5D5D 100%) border-box',
              borderColor: 'transparent',
              borderWidth: 2,
              color: '#000000DE',
              borderRadius: '8px'
            },
            '&:disabled': {
              background: 'none',
              borderColor: '#21212159',
              borderWidth: 2,
              color: '#00000059',
              borderRadius: '8px'
            },
            '&:hover': {
              background:
                'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, #212121 0%, #5D5D5D 100%) border-box',
              borderColor: 'transparent',
              borderWidth: 2,
              color: '#000000DE',
              opacity: 0.8,
              borderRadius: '8px'
            }
          },
          textPrimary: {
            '&:disabled': {
              color: '#FFFFFF'
            }
          },
          textSecondary: {
            '&:disabled': {
              color: '#21212159'
            }
          }
        }
      },
      MuiFab: {
        styleOverrides: {
          colorInherit: {
            transitionProperty: 'opacity',
            '&': {
              background: '#212529',
              color: '#000000DE',
              border: '1px solid #767C83'
            },
            '&:disabled': {
              color: '#000000DE'
            },
            '&:hover': {
              opacity: 0.8,
              background: '#727BFE'
            }
          },
          primary: {
            transitionProperty: 'opacity',
            '&': {
              background: 'linear-gradient(90deg, #727BFE 0%, #727BFE 100%)',
              color: '#FFFFFF'
            },
            '&:disabled': {
              background: '#212121',
              color: '#FFFFFF'
            },
            '&:hover': {
              opacity: 0.8
            }
          },
          secondary: {
            transitionProperty: 'opacity',
            '&': {
              background: 'linear-gradient(90deg, #727BFE 0%, #727BFE 100%)',
              color: '#FFFFFF'
            },
            '&:disabled': {
              background: '#21212159',
              color: '#FFFFFF'
            },
            '&:hover': {
              opacity: 0.8
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            background: '#0000000D',
            borderRadius: 10,
            overflow: 'hidden'
          },
          input: {
            color: '#FFFFFF',
            fontFamily: 'Inter, sans-serif',
            fontSize: '1rem !important',
            fontStyle: 'normal',
            fontWeight: 400,
            letterSpacing: 'normal',
            lineHeight: '1.5rem !important',
            paddingBottom: 6,
            paddingTop: 6,
            paddingLeft: 16,
            paddingRight: 16
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#CCCDCF',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '1rem !important'
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          colorPrimary: {
            color: '#727BFE',
            padding: 4,
            '&.Mui-checked': {
              color: '#727BFE'
            },
            '& .MuiSvgIcon-root': {
              fontSize: 14
            }
          },
          colorSecondary: {
            color: '#212121',
            padding: 4,
            '&.Mui-checked': {
              color: '#212121'
            },
            '& .MuiSvgIcon-root': {
              fontSize: 14
            }
          },
          root: {
            padding: 4,
            '& .MuiSvgIcon-root': {
              fontSize: 14
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: '#727BFE',
            marginRight: 8
          }
        }
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            height: 28,
            width: 28,
            backgroundColor: '#FFFFFF'
          },
          rail: {
            height: 12,
            color: '#0000000D',
            opacity: 1
          },
          track: {
            height: 12,
            color: 'transparent'
          },
          mark: {
            color: 'transparent'
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            background: '#212529 !important'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          // Name of the slot
          root: {
            background: '#212529 !important',
            border: '1px solid #767C83'
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthXs: {
            margin: 6
          },
          paperWidthSm: {
            margin: 6
          },
          paper: {
            background: 'black'
          },
          paperScrollBody: {
            width: 'calc(100% - 12px)'
          },
          root: {}
        }
      }
    },
    palette: {
      mode: 'dark',
      background: {
        default: '#212529',
        paper: '#FFFFFF'
      },
      text: {
        primary: '#F8F9FA',
        secondary: '#767C83'
      },
      primary: {
        main: '#727BFE'
      },
      secondary: {
        main: '#FFFFFF',
        light: '#3D4044'
      },
      complementary: {
        main: '#9FFF1F'
      },
      argent: {
        main: '#c0c0c0'
      },
      bronze: {
        main: '#b08d57'
      },
      gold: {
        main: '#ffd700'
      },
      info: {
        main: '#98BAEC'
      },
      success: {
        main: '#89D365',
        light: '#E7F6E0'
      },
      warning: {
        main: '#F5C42D',
        light: '#FEEBC8'
      },
      error: {
        main: '#E44E2D',
        light: '#ECC5BD'
      },
      yellow: {
        main: '#E2FF07'
      },
      orange: {
        main: '#FF9900'
      },
      red: {
        main: '#FF4545'
      },
      pink: {
        main: '#F80396'
      },
      purple: {
        main: '#B377FE'
      },
      green: {
        main: '#9FD408'
      },
      gray: {
        main: '#6F6F6F'
      }
    },
    shadows: darkShadows
  }
};

export const createCustomTheme = (props: ThemeProps): Theme => {
  let defaultTheme = constants.THEMES.LIGHT;
  let themeOptions = themesOptions[props.theme === constants.THEMES.DEFAULT ? defaultTheme : props.theme];
  if (!themeOptions) {
    console.warn(new Error(`The theme ${props.theme} is not valid`));
    themeOptions = themesOptions[defaultTheme];
  }

  let theme = createTheme(merge({}, baseOptions, themeOptions));

  theme = responsiveFontSizes(theme, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    disableAlign: false,
    factor: 2,
    variants: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'body1',
      'body2',
      'subtitle1',
      'subtitle2',
      'caption',
      'button',
      'overline'
    ]
  });

  return theme;
};
